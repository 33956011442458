import { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import {
  doorStatusColor,
  formatUpdatedAt,
  getBatteryColor,
  getBatteryType,
  getMissionCreationPayload,
  isAutomataDisabled,
  isLevel3Active,
  isLevel4Active,
} from './utils.ts';
import { patchVehicle, postCreateMission } from '../../../queries/getVehicles.ts';
import { Node } from '../Map/Map.tsx';
import { getNodeNameById, getShiftStatusColors } from '../../../utils.ts';
import { TerritoryConfig } from '../../../interfaces/TerritoryConfig.ts';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FormatTime } from '../../../utils.ts';
import { PLACE_ACTIVITIES } from '../../../constants.ts';
import { Log } from '../Logs/Log.tsx';
import { IVehicle } from '../../../interfaces/Vehicle.ts';

interface Props {
  vehicle: IVehicle;
  index: number;
  territorySlug: string;
  geography: Node[];
  territoryConfig: TerritoryConfig | undefined | null;
}

export function Vehicle(props: Props) {
  const { t } = useTranslation();
  const { selectedVehicle, setSelectedVehicle } = useContext(SelectedVehicleContext);
  const [isCreatingMission, setIsCreatingMission] = useState(false);
  const [vehicle, setVehicle] = useState(props.vehicle);
  const [showCreateMission, setShowCreateMission] = useState(false);
  const [territoryConfig, setTerritoryConfig] = useState<TerritoryConfig | undefined | null>(
    props.territoryConfig,
  );

  useEffect(() => {
    setVehicle(props.vehicle);
    if (vehicle?.shift && selectedVehicle !== undefined) {
      setSelectedVehicle({
        vehicleId: vehicle.id,
        vehicleDisplayIdentifier: vehicle.display_identifier,
        latitude: vehicle.latitude,
        longitude: vehicle.longitude,
        shift: vehicle.shift,
      });
    }
  }, [props.vehicle, props.vehicle?.shift?.status, props.vehicle?.shift]);

  useEffect(() => {
    setTerritoryConfig(props.territoryConfig);
  }, [props.territoryConfig]);
  const onEnter = () => {
    setSelectedVehicle({
      vehicleId: vehicle.id,
      vehicleDisplayIdentifier: vehicle.display_identifier,
      latitude: vehicle.latitude,
      longitude: vehicle.longitude,
      shift: vehicle.shift,
    });
  };
  const onExit = () => {
    setSelectedVehicle(undefined);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function onSubmitMissionCreation(event) {
    setIsCreatingMission(true);
    event.preventDefault();
    try {
      await postCreateMission(
        props.territorySlug,
        vehicle.id,
        getMissionCreationPayload(event, props.territoryConfig),
      );
      toast.success(`${t('vehicle.create-mission-toaster-success')} ${vehicle.display_identifier}`);
    } catch (e) {
      toast.error(`${t('vehicle.create-mission-toaster-error')} ${vehicle.display_identifier}`);
    } finally {
      setIsCreatingMission(false);
      setShowCreateMission(false);
    }
  }
  const handleStopAutomata = () => {
    const data = {
      automata_enabled: false,
    };
    try {
      patchVehicle(props.territorySlug, vehicle.id, data);
      setVehicle({ ...vehicle, automata_enabled: false });
      toast.success(
        `${t('vehicle.vehicle')} ${vehicle.display_identifier} automata ${t('vehicle.automata-off')}`,
      );
    } catch (e) {
      toast.error(
        `${t('vehicle.vehicle')} ${vehicle.display_identifier} ${t('vehicle.automata-update-error')}`,
      );
    }
  };

  const handleSetAutomataLevel = (level: string) => {
    try {
      const data = {
        automata_level: level,
        automata_enabled: true,
      };
      patchVehicle(props.territorySlug, vehicle.id, data);
      setVehicle({ ...vehicle, automata_level: level, automata_enabled: true });
      toast.success(
        `${t('vehicle.vehicle')} ${vehicle.display_identifier} automata ${level === 'LEVEL_3' ? t('vehicle.automata-level-3') : t('vehicle.automata-level-4')}`,
      );
    } catch (e) {
      toast.error(
        `${t('vehicle.vehicle')} ${vehicle.display_identifier} ${t('vehicle.automata-update-error')}`,
      );
    }
  };

  return (
    <Accordion.Item eventKey={String(props.index)} className={'basic-shadow rounded-3 mt-2'}>
      <Accordion.Header className={'rounded'}>
        <Row className={'w-100'}>
          <Col lg={12} xl={6} className={'my-auto text-start'}>
            <Row>
              <Col lg={4}>
                {vehicle.is_online ? (
                  <i className={'bi bi-wifi wifi-icon h2'} />
                ) : (
                  <i className={'bi bi-wifi-off wifi-off-icon h2'} />
                )}
              </Col>
              <Col lg={8} className={'my-auto'}>
                <p className={'text-bold m-0'}>{vehicle.display_identifier}</p>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xl={6} className={'my-auto ps-3 border-start'}>
            <p className={'text-bold shift-color'}>
              <img src={'/theme/media/shift.svg'} width="20" height="20" alt="Shift icon" />
              <span className={'ms-1'}>
                {vehicle.shift?.id ? `${vehicle.shift?.id}` : `${t('vehicle.no-shift')}`}
              </span>
            </p>
            {vehicle.shift?.id ? (
              <>
                <p className={'mt-1'}>
                  <img src={'/theme/media/clock.svg'} width="20" height="20" alt="Clock icon" />
                  <span className={'ms-1 text-muted'}>
                    {FormatTime(vehicle.shift?.start_datetime)} -{' '}
                    {FormatTime(vehicle.shift?.end_datetime)}
                  </span>
                </p>
                <p className={'text-basic mt-3 pb-1'}>
                  <span
                    className={'mission-lifecycle rounded-4'}
                    style={getShiftStatusColors(vehicle.shift?.status)}>
                    {vehicle.shift?.status}
                  </span>
                </p>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body onEntered={onEnter} onExit={onExit} className={'mb-3'}>
        <Container>
          <Form>
            <Row className={'text-start my-auto'}>
              <p className={'text-bold mb-2'}>{vehicle.display_identifier}</p>
            </Row>
            <Row>
              <Col lg={12} xl={5} className={'my-auto'}>
                <p className={'text-bold text-start'}>{t('vehicle.automata-enabled')}</p>
              </Col>
              <Col lg={12} xl={7} className={'my-auto text-start text-xl-end'}>
                <ButtonGroup className={'shadow-sm rounded-start-4 rounded-end-4'}>
                  <Button
                    className={'rounded-start-4 automata-button'}
                    onClick={() => handleStopAutomata()}
                    active={isAutomataDisabled(vehicle)}>
                    off
                  </Button>
                  <Button
                    className={'automata-button'}
                    onClick={() => handleSetAutomataLevel('LEVEL_3')}
                    active={isLevel3Active(vehicle)}>
                    3
                  </Button>
                  <Button
                    className={'rounded-end-4 automata-button'}
                    onClick={() => handleSetAutomataLevel('LEVEL_4')}
                    active={isLevel4Active(vehicle)}>
                    4
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Form>
          <Row className={'mt-2'}>
            <Col lg={12} xl={5}>
              <p className={'text-bold text-start'}>{t('vehicle.last-message-received')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {formatUpdatedAt(vehicle.updated_at)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-bold text-start'}>{t('vehicle.shuttle-provider')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>{vehicle.provider.toLowerCase()}</p>
            </Col>
          </Row>
          {territoryConfig?.vehicle.charge_percentage_display ? (
            <Row>
              <Col lg={12} xl={5}>
                <p className={'text-bold text-start'}>{t('vehicle.battery-level')}</p>
              </Col>
              <Col xs={12} xl={7}>
                <p className={'text-basic text-start text-xl-end'}>
                  <i
                    className={
                      'text-basic bi bi-battery' +
                      getBatteryType(Number(vehicle.charge_percentage)) +
                      ' ' +
                      'text' +
                      getBatteryColor(Number(vehicle.charge_percentage))
                    }>
                    {vehicle.charge_percentage}%
                  </i>
                </p>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {territoryConfig?.vehicle.doors_status_display ? (
            <Row>
              <Col lg={12} xl={5}>
                <p className={'text-bold text-start'}>{t('vehicle.door-status')}</p>
              </Col>
              <Col lg={12} xl={7}>
                <p
                  className={
                    'text-basic text-start text-xl-end text-' + doorStatusColor(vehicle.door_status)
                  }>
                  {vehicle.door_status.toLowerCase()}
                </p>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-bold text-start'}>{t('vehicle.robot-mode')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {props.vehicle.robot_mode.toLowerCase()}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-bold text-start'}>{t('vehicle.supervision-mode')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {props.vehicle.supervision_mode.toLowerCase()}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-bold text-start'}>{t('vehicle.latitude')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {props.vehicle.latitude?.toFixed(6) || '-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-bold text-start'}>{t('vehicle.longitude')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {props.vehicle.longitude?.toFixed(6) || '-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-bold text-start'}>{t('vehicle.current-node')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {getNodeNameById(props.geography, props.vehicle.current_node) || '-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={5}>
              <p className={'text-bold text-start'}>{t('vehicle.next-node')}</p>
            </Col>
            <Col lg={12} xl={7}>
              <p className={'text-basic text-start text-xl-end'}>
                {getNodeNameById(props.geography, props.vehicle.next_node) || '-'}
              </p>
            </Col>
          </Row>
          <hr className={'text-muted'} />
          <Row>
            <Col lg={12} xl={6} className={'mt-2'}>
              <Button onClick={() => setShowCreateMission(true)} className={'submit-button'}>
                <p>
                  <img src={'/theme/media/plus.svg'} width="25" height="25" alt="Plus icon" />
                  <span className={'ms-1 my-auto'}>{t('vehicle.create-mission-button')}</span>
                </p>
              </Button>
            </Col>
            <Col lg={12} xl={6} className={'mt-2'}>
              <Button disabled className={'submit-button'}>
                <p>
                  <img src={'/theme/media/stack.svg'} width="25" height="25" alt="Clock icon" />
                  <span className={'ms-1 my-auto'}>{t('vehicle.mission-stack')}</span>
                </p>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xl={6} className={'mt-2'}>
              <Log
                territorySlug={props.territorySlug}
                vehicle={props.vehicle}
                territoryConfig={props.territoryConfig}
              />
            </Col>
          </Row>
          <div className={'create-mission-modal'}>
            <Modal show={showCreateMission} onHide={() => setShowCreateMission(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>
                  <p className={'card-subtitle'}>Vehicle - {props.vehicle.display_identifier}</p>
                </Modal.Title>
              </Modal.Header>
              <Form onSubmit={onSubmitMissionCreation}>
                <Modal.Body className={'justify-content-between'}>
                  <Form.Group as={Row} className={'d-flex align-items-center'}>
                    <Col sm={3}>
                      <Form.Label>Destination</Form.Label>
                    </Col>
                    <Col sm={9}>
                      <Form.Select id={'nodesSelect'} className={'rounded-5'}>
                        {props.geography?.map((node, i) => (
                          <option value={Number(node?.padam_id)} key={i}>
                            {node?.padam_id} {node?.padam_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  {territoryConfig?.mission.activity_enabled &&
                  territoryConfig?.mission.waypoints_enabled ? (
                    <>
                      <Form.Group as={Row} className={'d-flex align-items-center mt-3'}>
                        <Col sm={3}>
                          <Form.Label>{t('vehicle.activity')}</Form.Label>
                        </Col>
                        <Col sm={9}>
                          <Form.Select id={'activitiesSelect'} className={'rounded-5'}>
                            {PLACE_ACTIVITIES.map(activity => (
                              <option value={activity.number} key={activity.number}>
                                {activity.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </>
                  ) : (
                    <></>
                  )}
                </Modal.Body>
                <Modal.Footer className={'border-0'}>
                  <Button
                    type={'submit'}
                    className={'submit-button m-auto mb-3 mt-2'}
                    disabled={isCreatingMission}>
                    {isCreatingMission ? (
                      <span className={'d-flex justify-content-center'}>
                        <Spinner size={'sm'} animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <span className={'ms-2'}>{t('vehicle.create-mission-submit')}</span>
                      </span>
                    ) : (
                      <p>
                        <img src={'/theme/media/plus.svg'} width="25" height="25" alt="Clock icon" />
                        <span className={'ms-1'}>{t('vehicle.create-mission-submit')}</span>
                      </p>
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}
